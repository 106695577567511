.remove__button {
  color: lightgray;
}
.remove__button:hover {
  color: rgb(114, 111, 111);
}

a,
a:hover {
  text-decoration: none;
}
